import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, Provider } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import ReactGA from 'react-ga';
  
import { loadUser } from '../actions/users-actions'
import { loadValidate } from '../actions/authentication-actions'

import PrivateRoute from '../components/PrivateRoute'


import Footer from '../components/Footer'
import Header from './Header'
import Page404 from './Page404'
import Welcome from './Welcome' 
import Home from './Home' 
import UnlockGame from './UnlockGame' 
import Game from './Game' 
 

import createHistory from 'history/createBrowserHistory'

const history = createHistory()
history.listen(location => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
})

class Root extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired, 
    loadEventCategories: PropTypes.func,
    loadPages: PropTypes.func.isRequired,
    loadRoles: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    loadValidate: PropTypes.func.isRequired
  }

  componentWillMount() {  

    const token = localStorage && localStorage.getItem('token') ? localStorage.getItem('token') : false;
    if (token) {
      this.props.loadValidate()
        .then((response) => {
          this.props.loadUser('me')
        })
    }
    this.props.history.listen(location => ReactGA.pageview(location.pathname));
    this.props.history.listen(location => window.scrollTo(0, 0));
 
    try {
      navigator.wakeLock.request('screen');
    } catch (err) {
      // Error or rejection
      console.log('Wake Lock error: ', err);
    }
  }

  componentDidMount() {
        ReactGA.pageview(window.location.pathname)
    }

  render() {
    const { store } = this.props

    return (
      <Provider history={history} store={store}>
        <div>
          <Header />
          <main>
            <Switch>
              <Route exact path="/" component={Welcome}/>
              <Route exact path="/story" component={Home}/>
              <Route exact path="/unlock" component={UnlockGame}/>
              <Route exact path="/game" component={Game}/>
              <Route component={Page404} />
            </Switch>
          </main> 
        </div>
      </Provider>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {}
}

export default withRouter(connect(mapStateToProps, {
  loadUser,
  loadValidate
})(Root))
