import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import map from 'lodash/map'

import { loadMenus } from '../actions'
import { logout } from '../actions/authentication-actions'
import { stripUrlKnownDomain } from '../utils';

import isExternal from 'is-url-external';

class Menus extends Component {
  constructor(props) {
    super(props); 
    this.handleLogout = this.handleLogout.bind(this);
  }

  static propTypes = {
    authentication: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired
  }

 

  handleLogout() {
    this.props.history.push('/');
    this.props.logout();
  }



  render() {
    const user = this.props.user;
    const authentication = this.props.authentication;
 
 

 
 

    return (
      <header>
         
      </header>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  const {
    authentication, 
    user
  } = state
 

  return {
    authentication,
    user
  }
}

export default withRouter(connect(mapStateToProps, {
  loadMenus,
  logout
})(Menus))
