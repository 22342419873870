import React, { Component } from 'react'; 
import Vimeo from '@u-wave/react-vimeo'; 
 

export default class UnlockModalContent extends Component {

 
 
  render() {

    const {type,content} = this.props;
    
    return (
      
      <div>

        {type == "story" &&
        <button type="button" class="btn btn-primary" data-toggle="modal"  data-target={"#storyModal"+content.id} onClick={() => this.props.setUnlockedCharacters(content.unlockCharacter, content.id, content.order)} dangerouslySetInnerHTML={{__html: content.title.rendered}}>
          
        </button>
        }

        {type != "story" &&
        <button type="button" class="btn btn-primary" data-toggle="modal"  data-target={"#storyModal"+content.id} dangerouslySetInnerHTML={{__html: content.title.rendered}}>
        </button>
        }
   
        <div class="modal fade" id={"storyModal"+content.id} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"dangerouslySetInnerHTML={{__html: content.title.rendered}}></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><img src="/images/close.png" /></span>
                </button>
              </div>
              <div class="modal-body">  
                <div dangerouslySetInnerHTML={{__html: content.content.rendered}}></div>
                <div>
                  <p><Vimeo
                  video={content.videoUrl}
                  width="300"
                  autoplay="false"
                  />
                  </p> 
                </div>
                {type == "story" &&
                  <div>You have unlocked the letter: {content.unlockCharacter}</div>
                }
                
              </div> 
            </div>
          </div>
        </div>
      </div>

    ) 
  }
}
