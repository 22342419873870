import { CALL_API, Schemas } from '../middleware/api'

export const USERS_REQUEST = 'USERS_REQUEST'
export const USERS_SUCCESS = 'USERS_SUCCESS'
export const USERS_FAILURE = 'USERS_FAILURE'

const fetchUsers = () => ({
  [CALL_API]: {
    types: [ USERS_REQUEST, USERS_SUCCESS, USERS_FAILURE ],
    endpoint: `listallusersapiroute`,
    schema: Schemas.USER_ARRAY
  }
})

export const loadUsers = () => (dispatch, getState) => {
  const users = getState().entities.users;
  if (Object.keys(users).length > 0) {
    return null
  }

  return dispatch(fetchUsers())
}

export const USER_REQUEST = 'USER_REQUEST'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_FAILURE = 'USER_FAILURE'

const fetchUser = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ USER_REQUEST, USER_SUCCESS, USER_FAILURE ],
    endpoint: `secure/users/${userId}`,
    schema: userId === 'me' ? null : Schemas.USER
  }
})

export const loadUser = (userId) => (dispatch, getState) => {
  const user = getState().entities.users[userId];
  if (user) {
    return null
  }

  return dispatch(fetchUser(userId))
}


export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

const fetchUpdateUserCategoryPreference = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE ],
    endpoint: 'secure/users/update-category',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateUserCategoryPreference = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateUserCategoryPreference(body))
};


export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST'
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS'
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE'

const fetchUpdateUserProfile = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_USER_PROFILE_REQUEST, UPDATE_USER_PROFILE_SUCCESS,UPDATE_USER_PROFILE_FAILURE ],
    endpoint: 'secure/users/update-profile',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateUserProfile  = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateUserProfile(body))
};


export const UPLOAD_PROFILE_IMAGE_REQUEST = 'UPLOAD_PROFILE_IMAGE_REQUEST'
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'UPLOAD_PROFILE_IMAGE_SUCCESS'
export const UPLOAD_PROFILE_IMAGE_FAILURE = 'UPLOAD_PROFILE_IMAGE_FAILURE'

const fetchUpdateUserProfilePicture = (body) => ({
  [CALL_API]: {
    types: [ UPLOAD_PROFILE_IMAGE_REQUEST, UPLOAD_PROFILE_IMAGE_SUCCESS, UPLOAD_PROFILE_IMAGE_FAILURE ],
    endpoint: 'secure/users/upload-profile-picture',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateUserProfilePicture  = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateUserProfilePicture(body))
};




export const USER_EVENT_REQUEST = 'USER_EVENT_REQUEST'
export const USER_EVENT_SUCCESS = 'USER_EVENT_SUCCESS'
export const USER_EVENT_FAILURE = 'USER_EVENT_FAILURE'

const fetchUserEvents = () => ({
  [CALL_API]: {
    types: [ USER_EVENT_REQUEST, USER_EVENT_SUCCESS, USER_EVENT_FAILURE ],
    endpoint: `secure/users/events`,
    schema: Schemas.USER_EVENTS_ARRAY
  }
})

export const loadUserEvents = () => (dispatch, getState) => {
  return dispatch(fetchUserEvents())
}



 
export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST'
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS'
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE'

const fetchDeleteEvent = (body) => ({
  [CALL_API]: {
    types: [ DELETE_EVENT_REQUEST, DELETE_EVENT_SUCCESS, DELETE_EVENT_FAILURE ],
    endpoint: 'secure/events/delete-event',
    schema: null,
    method: 'POST', 
    body: body
  }
})


export const loadDeleteEvent  = (body) => (dispatch, getState) => {

  const userEvents = getState().entities.userEvents;

  if (Object.keys(userEvents).length > 0) {
    Object.keys(userEvents).map(function(key) {

      delete userEvents[key];

    })  
  }
  
  return dispatch(fetchDeleteEvent(body))
};

 
