import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom' 
import Loader from '../components/Loader'
import Webcam from "react-webcam";
import { Link } from 'react-router-dom' 

import overlay from '../images/frame.png';
import { loadStoryLocations } from '../actions/story-actions'
import Vimeo from '@u-wave/react-vimeo'; 
 

class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
       unlockedCharacters: [],
       error: false,
       wordUnlocked: false,
       imageSrc: null,
       selfieUnlocked: false,
       videoConstraints: { width: 300,
  height: 300,
  facingMode: "user",
  mirrored: true,
  hideVideo: false }
    
    }  

    this.onSubmit = this.onSubmit.bind(this);
    this.captureAgain = this.captureAgain.bind(this);
    this.unlockSelfie = this.unlockSelfie.bind(this);
     

  }

  static propTypes = { 
    loadStoryLocations: PropTypes.func.isRequired
  } 

  componentWillMount() {
    var page = this;
    this.props.loadStoryLocations();

    var unlockedCharacters = JSON.parse(localStorage.getItem("unlockedCharacters") || "[]");
    this.setState({ unlockedCharacters: unlockedCharacters });
  }

  displayUnlockedCharacters() {
    const {unlockedCharacters} = this.state;
    
    return unlockedCharacters.map((content, index) => (

      <div class="mr-2">{content.character} </div>
    ));
 
  }

  setRef = webcam => {
    this.webcam = webcam;
  };

  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    this.setState({ imageSrc: imageSrc });
 

    var page = this;
    var ctx = this.refs.canvas.getContext('2d');

    var drawing = new Image();
    var drawing2 = new Image();
    drawing2.src = imageSrc;
    drawing.src = overlay;
    drawing.onload = function() {
      ctx.drawImage(drawing2,0,0);
      ctx.drawImage(drawing,0,0);

      var pngUrl = page.refs.canvas.toDataURL();


      var image = page.refs.canvas.toDataURL("image/jpg");
      page.refs.link.href = image;

      page.setState({ hideVideo: true });
        
    };

    
 


  };

  captureAgain() {
    this.setState({ hideVideo: false });
  }

  unlockSelfie() {
    this.setState({ selfieUnlock: true });
  }


  onSubmit(event) {

    event.preventDefault();
    if (event.target.checkValidity() !== false) {
      if (this.refs.phrase.value) {
        this.setState({
          error: false
        }) 
 
        if (this.refs.phrase.value == 'ADELARD' || this.refs.phrase.value == 'adelard' || this.refs.phrase.value == 'Adelard') {

          this.setState({
            wordUnlocked: true
          })  
        } else {
          this.setState({
            error: true
          })  
        }
      }
    }
    event.target.classList.add('was-validated');
  }
 

 

  render() {
    const {stories} = this.props;
    const { imageSrc } = this.state;
    const facingMode = this.state.videoConstraints.facingMode;


    var gameEnabled = true; 
    if (Object.keys(stories).length == this.state.unlockedCharacters.length) {
      gameEnabled = true;
    }

    return (
      <div class="vh-100 py-5">
      {this.state.selfieUnlock ? ( 

        <div class="">

          <div class="col-12 text-center text-light">

            <div id="camFrame">

              {!this.state.hideVideo &&  
                <div>
                <h1>Take a selfie and share your adventure!</h1>
                <Webcam
                audio={false}
                height={300}
                ref={this.setRef}
                width={300}
                screenshotFormat="image/jpeg"
                videoConstraints={this.state.videoConstraints}
                mirrored={true}
                /> 
                <button className="btn btn-primary" onClick={this.capture}>Capture photo</button>
                </div>
              }
              
              <div class="row">
                <div class="col-12">
                  <canvas style={{ display: !this.state.hideVideo ? 'none': ''}} ref="canvas" id="canvas" width="300" height="300"></canvas>
                </div>
                <div class="col-12 mb-3">
                  <button style={{ display: !this.state.hideVideo ? 'none': ''}} className="btn btn-primary" onClick={this.captureAgain}>Take Another Picture</button>
                </div>
                <div class="col-12 mb-3">
                  <a style={{ display: !this.state.hideVideo ? 'none': ''}} className="btn btn-primary" download="myImage.jpg" href="" ref="link">Download photo</a>
                </div>
                <div class="col-12">
                  <Link class="btn btn-primary" to="/story">Return to story</Link>
                </div>
              </div>
            
            </div>
          </div>

        </div>
          
          
      ) : (

        <div>
          <div class="container">

          {gameEnabled ? ( 
            <div class="row">

              {this.state.wordUnlocked ? (
                
                <div class="col-12 text-center text-light">
                  <p>
                  <Vimeo
                      video="https://player.vimeo.com/video/611605269?h=8adb276b00&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      width="300"
                      autoplay="false"
                      />
                  </p>

                  <button className="btn btn-primary" onClick={this.unlockSelfie}>
                    Unlock Selfie
                  </button> 

                </div>

              ) : (

                <div class="col-12 text-center text-light">
                  <h1>You found all the letters</h1> 
               
                  <p>Guess my name below to unlock this film & the secret of Time Travel</p>
                  <h3>Unlocked Characters:</h3>
                  <div class="d-flex align-items-center justify-content-center">
                    {this.displayUnlockedCharacters()}
                  </div>
            

                  <form className="mt-4" onSubmit={this.onSubmit} >
                    <div className="form-group row"> 
                      <div className="col-12">  
                        <input type="text" className="form-control" name="phrase" id="phrase"  ref="phrase" value={this.state.phrase} onChange={e => this.setState({ phrase: e.target.value })}  required />
                        <div className="invalid-feedback">
                          Please enter the secret phrase.
                        </div>
                      </div>
                      {this.state.error && (  
                        <div className="col-12 mt-2">
                          <div class="alert alert-danger" role="alert">
                            That's not it! Please try again.
                          </div>
                        </div>
                      )}
                    </div>

                 

                    <div className="form-group">
                      <div className="col-12">
                        <button type="submit" className="btn btn-primary">
                          Guess
                        </button> 
                      </div>
                    </div>
                  </form>
                </div>

              )}

              

               
 
            </div>
          ) : (

            <div class="row">

              <div class="col-12 text-center text-light">
                <h1>Oops, you're not supposed to be here</h1>
                
                <Link class="btn btn-primary" to="/story">Return to story</Link>
              </div>
 
            </div>
             

          )}
            
          </div>
        </div>

      )}
      </div>


    )
  }
}

const mapStateToProps = (state, ownProps) => {

  const { 
    entities: { stories }
  } = state  

  return {
    stories
 
  }
}

export default withRouter(connect(mapStateToProps, {
  loadStoryLocations
})(Game))