const dev = { 
};

const prod = { 
};


global.config = {
   MAP_KEY: "pk.eyJ1IjoiZ2VvcmdlbWNjYW5uIiwiYSI6ImNrcHB0aXo2YTB5eWUybm51b3ptdnd1cnIifQ.2SRDf2D2wRsx18xIXnyfpQ"
};


// Default to dev if not set  pk_live_HtdzO35fojjONfkBK5VtAzzf
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
}; 