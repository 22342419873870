
import React, { Component, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom' 
 
import { loadStoryLocations } from '../actions/story-actions'

class UnlockGame extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
       unlockedCharacters: [],
       error: false,
       wordUnlocked: false
    } 
    
    this.onSubmit = this.onSubmit.bind(this);
     

  }

  static propTypes = { 
    loadStoryLocations: PropTypes.func.isRequired
  } 


  

 
  componentWillMount() {
    var page = this;
    this.props.loadStoryLocations();

    var unlockedCharacters = JSON.parse(localStorage.getItem("unlockedCharacters") || "[]");
    this.setState({ unlockedCharacters: unlockedCharacters });
  }

  displayUnlockedCharacters() {
    const unlockedCharacters = this.state.unlockedCharacters;
 
    return unlockedCharacters.map((content, index) => (

      <div>{content.character} </div>
    ));
 
  }

  onSubmit(event) {
    const {stories} = this.props;
    event.preventDefault();
    var phrase = this.refs.phrase.value;

    if (phrase.length != Object.keys(stories).length) {
      this.setState({
        error: true,
        message: "Please use all the unlocked letters"
      }) 

    }
    

    if (phrase != "test") {
      this.setState({
        error: true,
        message: "This is not the secret word!"
      }) 

    } else {
      this.setState({
        error: false,
        message: "This is the secret word!",
        wordUnlocked: true
      }) 
      localStorage.setItem("gameComplete", true);

    }






  }


  checkError() {
    if (this.state.error) {
      return (
        <div key='error'>
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <div dangerouslySetInnerHTML={{__html: this.state.message}}></div>
          </div>
        </div>
      );
    } else {
      return '';
    }
  }
  

  render() {

    const {stories} = this.props;

    
    var gameEnabled = false;
    console.log(Object.keys(stories).length);
    console.log(this.state.unlockedCharacters.length);
    if (Object.keys(stories).length == this.state.unlockedCharacters.length) {
      gameEnabled = true;
    }
 
    return (
      <div>

        {gameEnabled ? ( 
          <div>
            GAME ENABLED
          </div>
          
        ) : (
          <div>
            <div>

              {this.state.wordUnlocked ? (
                <div>
                  <div>YOU HAVE UNLOCKED A SECRET</div>
                  <Link to="/selfie" className="mx-auto">Go to secret</Link>
                </div>

              ) : (

                <div>

                  YOU HAVE NOT UNLOCKED EVERY LETTER YET!
                  {this.displayUnlockedCharacters()}

                  <form className="needs-validation" onSubmit={this.onSubmit} noValidate >
                    <div className="form-group row"> 
                      <div className="col-12"> 
                        <label className="sr-only" for="phrase">Unlock Word</label>
                        <input type="text" className="form-control" name="phrase" id="phrase" placeholder="Enter the secret word" ref="phrase" value={this.state.phrase} onChange={e => this.setState({ phrase: e.target.value })} autoComplete="phrase" required />
                      </div>
                    </div>

                    {this.checkError()}
       

                    <div className="form-group row mb-0">
                      <div className="col-12">
                        <button type="submit" className="btn align-right m-0 w-100">
                          Submit
                        </button>   
                      </div>
                    </div>
                  </form>


                </div>

              )}

              
            </div>     
          </div>
             
        )}
         
        
      </div>
     
    )
  }
}


const mapStateToProps = (state, ownProps) => {

  const { 
    entities: { stories }
  } = state  

  return {
    stories
 
  }
}

export default withRouter(connect(mapStateToProps, {
  loadStoryLocations
})(UnlockGame))

 