import React, {Component} from 'react';
import { Link } from 'react-router-dom' 


export default class Footer extends Component {
  render() {
    return (
      <footer> 
        <div className="py-2 bg-dark text-center">
          <div className="col-12">
            <div className="container font-small">
       
              <Link to="/terms-and-conditions">Terms &amp; Conditions</Link> - <Link to="/privacy-policy">Privacy Policy</Link> - <Link to="/cookie-policy">Cookie Policy</Link>
              
         
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
