import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom' 
import Loader from '../components/Loader'

class Page404 extends Component {
  constructor(props) {
    super(props);
  }
 

  render() {
 
    return (
      <div className="404page"> 
        <div className="pt-3 pb-3 container">
          <div className="row">
            <div className="col-12 text-center">
              <h2>404 - Page not found</h2>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {}
}

export default withRouter(connect(mapStateToProps, {
 
})(Page404))