import React, { Component } from 'react';  
import { Link } from 'react-router-dom' 
export default class UnlockedCharacters extends Component {

  displayUnlockedCharacters() {
    const {unlockedCharacters} = this.props;
    if (unlockedCharacters.length > 0) {
      return unlockedCharacters.map((content, index) => (

        <div class="mr-2">{content.character} </div>
      ));
    } else {
      return (
        <div class="mr-2">None so far</div>
      )
    }
     
 
  }
 
  render() {
 
    
    return (

      <div id="unlockedCharacters">
        <div class="row align-items-center">
          <div class="col-5 text-left">
            <h5 class="m-0">Unlocked Letters: </h5>
          </div>
          <div class="col-7 text-right">
            <div class="d-flex">
              {this.displayUnlockedCharacters()}
            </div>
          </div>
          <div class="position-absolute text-right" style={{right: 0, top: '60px'}}>
             
            <Link to="/"><div class="homeBtn"><i class="fas fa-home"></i></div></Link>
     
          </div>
        </div>
      </div>

    ) 
  }
}
