import { CALL_API } from '../middleware/api';

/**
 * Token
 */
export const TOKEN_REQUEST = 'TOKEN_REQUEST';
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
export const TOKEN_FAILURE = 'TOKEN_FAILURE';

const fetchToken = (username, password) => ({
  username,
  password,
  [CALL_API]: {
    types: [TOKEN_REQUEST, TOKEN_SUCCESS, TOKEN_FAILURE],
    endpoint: 'login',
    method: 'POST',
    body: {
      username,
      password,
    },
  },
});

export const loadToken = (username, password) => (dispatch) => {
  return dispatch(fetchToken(username, password));
};

/**
 * Login
 */

export const VALIDATE_REQUEST = 'VALIDATE_REQUEST';
export const VALIDATE_SUCCESS = 'VALIDATE_SUCCESS';
export const VALIDATE_FAILURE = 'VALIDATE_FAILURE';

const fetchValidate = () => ({
  [CALL_API]: {
    types: [VALIDATE_REQUEST, VALIDATE_SUCCESS, VALIDATE_FAILURE],
    endpoint: 'secure/validate',
    method: 'GET',
  },
});

export const loadValidate = () => dispatch => dispatch(fetchValidate());

/**
 * Logout
 */

export const LOGOUT = 'LOGOUT';

export const logout = () => dispatch => dispatch({
  type: LOGOUT,
});

/**
 * Register
 */
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

const fetchRegister = body => ({
  [CALL_API]: {
    types: [REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE],
    endpoint: 'add-account',
    method: 'POST',
    body,
  },
});

export const doRegistration = data => dispatch => dispatch(fetchRegister(data));


/**
 * Register
 */
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
 
const fetchReset = (email) => ({
  email, 
  [CALL_API]: {
    types: [ RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE ],
    endpoint: `/reset-password`,
    method: 'POST',
    body: {
      email: email,
    }
  }
})

export const doReset = data => dispatch => dispatch(fetchReset(data));



export const PASSWORD_CHECK_REQUEST = 'PASSWORD_CHECK_REQUEST';
export const PASSWORD_CHECK_SUCCESS = 'PASSWORD_CHECK_SUCCESS';
export const PASSWORD_CHECK_FAILURE = 'PASSWORD_CHECK_FAILURE';

const fetchPasswordCheck = (password) => ({
  password,
  [CALL_API]: {
    types: [PASSWORD_CHECK_REQUEST, PASSWORD_CHECK_SUCCESS, PASSWORD_CHECK_FAILURE],
    endpoint: 'secure/check-password',
    method: 'POST',
    body: {
      password,
    },
  },
});

export const loadPasswordCheck = (password) => (dispatch) => {
  return dispatch(fetchPasswordCheck(password));
};



export const TOKEN_CHECK_REQUEST = 'TOKEN_CHECK_REQUEST'
export const TOKEN_CHECK_SUCCESS = 'TOKEN_CHECK_SUCCESS'
export const TOKEN_CHECK_FAILURE = 'TOKEN_CHECK_FAILURE'

const fetchResetToken = (token) => ({
  token,
  [CALL_API]: {
    types: [ TOKEN_CHECK_REQUEST, TOKEN_CHECK_SUCCESS, TOKEN_CHECK_FAILURE ],
    endpoint: `check-reset-token/${token}`,
    schema: null
  }
})

export const checkResetToken = (token) => (dispatch, getState) => {
  return dispatch(fetchResetToken(token))
};



export const PASSWORD_UPDATE_REQUEST = 'PASSWORD_UPDATE_REQUEST';
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_UPDATE_FAILURE = 'PASSWORD_UPDATE_FAILURE';

const fetchUpdatePassword = (password, customerId, token) => ({
  password,
  customerId,
  token,
  [CALL_API]: {
    types: [PASSWORD_UPDATE_REQUEST, PASSWORD_UPDATE_SUCCESS, PASSWORD_UPDATE_FAILURE],
    endpoint: '/update-password',
    method: 'POST',
    body: {
      password,
      customerId,
      token
    },
  },
});

export const loadUpdatePassword = (password, customerId, token) => (dispatch) => {
  return dispatch(fetchUpdatePassword(password, customerId, token));
};