
import React, { Component, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom' 
//import mapboxgl from "mapbox-gl"
import ReactMapGL, { Marker, GeolocateControl } from "react-map-gl";
import * as turf from "@turf/turf";

import UnlockModal from '../components/UnlockModal/UnlockModal';
import UnlockedCharacters from '../components/UnlockedCharacters/UnlockedCharacters';

import { loadStoryLocations, loadResearchLocations } from '../actions/story-actions'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

class HomePageTemplate extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        width: "100vw",
        height: "100vh",
        latitude: 42.430472,
        longitude: -123.334102,
        zoom: 16
      },
      mounted: false,
      userLocation: {},
      unlocks: [],
      researchUnlocks: [],
      unlockedCharacters: [],
      unlockedNumber: 1,
      locationSet: 0
    } 

    this.setUserLocation = this.setUserLocation.bind(this);
    this.setUnlockedCharacters = this.setUnlockedCharacters.bind(this);

  }

  static propTypes = { 
    loadStoryLocations: PropTypes.func.isRequired,
    loadResearchLocations: PropTypes.func.isRequired
  } 


  

 
  componentWillMount() {
    var page = this;
    this.props.loadStoryLocations().then(function(response) {
      page.setUserLocation();
    });

    this.props.loadResearchLocations();

    var unlockedCharacters = JSON.parse(localStorage.getItem("unlockedCharacters") || "[]");
    this.setState({ unlockedCharacters: unlockedCharacters });

    var unlockedNumber = localStorage.getItem("unlockedNumber");
    if (unlockedNumber) {
      this.setState({ unlockedNumber: unlockedNumber });
    }
    
  }
 

  componentDidMount () {
    this.setState({ mounted: true })
  }

  setUserLocation(clickedSet=0) { 
    let page = this;
    const {stories, researchers} = this.props;
    if(clickedSet == 1) {
      this.setState({ locationSet: 1 });
    }
    navigator.geolocation.getCurrentPosition(
    function(position) {
      let setUserLocation = {
        lat: position.coords.latitude,
        long: position.coords.longitude
      }; 

      let newViewport;

      if (clickedSet != 0) {

        newViewport = {
           height: "100vh",
           width: "100vw",
           latitude: position.coords.latitude,
           longitude: position.coords.longitude,
           zoom: 16
        };

      } else {
        newViewport = {
           height: "100vh",
           width: "100vw",
           latitude: position.coords.latitude,
           longitude: position.coords.longitude,
           zoom: page.state.viewport.zoom
        };

      }
      console.log('v.1.0.0');
      console.log(newViewport)
       
       page.setState({
         viewport: newViewport,
         userLocation: setUserLocation
       });

     

      let distance;
      var unlocks = [];
      var researchUnlocks=[];
      Object.keys(stories).map(function(key) {

        distance = turf.distance(
          turf.point([page.state.userLocation.long, page.state.userLocation.lat]),
          turf.point([parseFloat(stories[key].longitude), parseFloat(stories[key].latitude)])
        ); 

        if (distance <= 0.025) {
 
          unlocks.push(key);
        }

      })

      Object.keys(researchers).map(function(key) {

        distance = turf.distance(
          turf.point([page.state.userLocation.long, page.state.userLocation.lat]),
          turf.point([parseFloat(researchers[key].longitude), parseFloat(researchers[key].latitude)])
        ); 

        if (distance <= 0.025) {
 
          researchUnlocks.push(key);
        }

      })

      page.setState({
        unlocks: unlocks,
        researchUnlocks: researchUnlocks
      })


 
      
    },
    function(error){
      console.log(error.message);
    }, {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: Infinity
    }
    );

     
  }

  loadStoryMarkers () {

    const {stories} = this.props;

    return (Object.keys(stories).map(function(key) {
    
      return (
        <Marker latitude={parseFloat(stories[key].latitude)} longitude={parseFloat(stories[key].longitude)} offsetLeft={-35} offsetTop={-60}><div style={{position: 'relative'}}><img src="/images/mainMarker.png" /><h5 style={{position: 'absolute', width: '100%', textAlign: 'center', bottom: '-5px', left: 0}}>{stories[key].order}</h5></div></Marker>
      )

    }));

 

  }


  loadResearchMarkers () {

    const {researchers} = this.props;

    return (Object.keys(researchers).map(function(key) {
    
      return (
        <Marker latitude={parseFloat(researchers[key].latitude)} longitude={parseFloat(researchers[key].longitude)} offsetLeft={-35} offsetTop={-60}><div style={{position: 'relative'}}><img src="/images/researchMarker.png" /></div></Marker>
      )

    }));

 

  }

  displayUnlocks(unlocks) {
    unlocks.forEach(function (storyId) {
      console.log(storyId);

    });
  }

  setUnlockedCharacters(character, storyId, order) {
    console.log(character + ' ' + storyId)

    const {type,content} = this.props;
    
    var unlockedCharacters = JSON.parse(localStorage.getItem("unlockedCharacters") || "[]");
    
    if (unlockedCharacters.filter(item=> item.storyId == storyId).length == 0){
      unlockedCharacters.push({"character":character, "storyId": storyId}); 
      localStorage.setItem("unlockedCharacters", JSON.stringify(unlockedCharacters));
      localStorage.setItem("unlockedNumber",  parseInt(order)+1);

      this.setState({ unlockedCharacters: unlockedCharacters }); 
      this.setState({ unlockedNumber: parseInt(order)+1})
    } else {
      console.log("already unlocked!");
    }

    
  }

 

  render() {
 
    const { mounted, unlocks, researchUnlocks, unlockedNumber } = this.state
    const geolocateControlStyle= {
      right: 10,
      top: 10
    };
    const {stories, researchers} = this.props; 
    var displayUnlockModal = false;
    if (unlocks.length > 0) {
      displayUnlockModal = true;
    }
    if (researchUnlocks.length > 0) {
      displayUnlockModal = true;
    }

    if (Object.keys(this.props.stories).length == this.state.unlockedCharacters) {
      displayUnlockModal = true;
    }
    return (
      <>
      {Object.keys(this.props.stories).length > 0 && (
 
      <div class="position-relative vh-100">
        <UnlockedCharacters unlockedCharacters={this.state.unlockedCharacters} />

        {this.state.locationSet == 0 && (
          <div id="findMe">
            <h3>To begin, we must find your location</h3>
            <button className="btn m-auto btn-primary" onClick={(e) => this.setUserLocation(1)}>Get My location</button>
          </div>
        )}
        
        <ReactMapGL  
          {...this.state.viewport} 
          width="100vw"
          height="100vh"
          onViewportChange={(viewport) => {
            this.setState({ viewport }) 
          }}  
          mapboxApiAccessToken={global.config.MAP_KEY}
          > 

          <GeolocateControl
          style={geolocateControlStyle}
          positionOptions={{enableHighAccuracy: true}}
          trackUserLocation={true}
          followUserLocation={true}
          auto 
          onGeolocate={() => this.setUserLocation()}

          />
 
 
          {this.loadStoryMarkers()}
          {this.loadResearchMarkers()}
          

        </ReactMapGL>
 
        {displayUnlockModal && (  
          <UnlockModal storiesLength={Object.keys(this.props.stories).length} unlockedCharacters={this.state.unlockedCharacters.length} unlockedNumber={unlockedNumber} unlocks={unlocks} researchUnlocks={researchUnlocks} stories={stories} researchers={researchers} setUnlockedCharacters={this.setUnlockedCharacters.bind(this)} />
        )}
        
      </div>
      )}
      </>
       
     
    )
  }
}


const mapStateToProps = (state, ownProps) => {

  const { 
    entities: { stories, researchers }
  } = state  

  return {
    stories,
    researchers
 
  }
}

export default withRouter(connect(mapStateToProps, {
  loadStoryLocations,
  loadResearchLocations
})(HomePageTemplate))

 