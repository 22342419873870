import { CALL_API, Schemas} from '../middleware/api';

/**
 * Token

export const TOKEN_REQUEST = 'TOKEN_REQUEST';
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
export const TOKEN_FAILURE = 'TOKEN_FAILURE';

const fetchToken = (username, password) => ({
  username,
  password,
  [CALL_API]: {
    types: [TOKEN_REQUEST, TOKEN_SUCCESS, TOKEN_FAILURE],
    endpoint: 'login',
    method: 'POST',
    body: {
      username,
      password,
    },
  },
});

export const loadToken = (username, password) => (dispatch) => {
  return dispatch(fetchToken(username, password));
};

 */

/**
 * Login
 */

export const GET_STORY_LOCATIONS_REQUEST = 'GET_STORY_LOCATIONS_REQUEST'
export const GET_STORY_LOCATIONS_SUCCESS = 'GET_STORY_LOCATIONS_SUCCESS'
export const GET_STORY_LOCATIONS_FAILURE = 'GET_STORY_LOCATIONS_FAILURE'

const fetchStoryLocations = () => ({
  [CALL_API]: {
    types: [ GET_STORY_LOCATIONS_REQUEST, GET_STORY_LOCATIONS_SUCCESS, GET_STORY_LOCATIONS_FAILURE ],
    endpoint: `/wp-json/wp/v2/story/`,
    schema: Schemas.STORIES_ARRAY
  }
})

export const loadStoryLocations = () => (dispatch, getState) => {
  const stories = getState().entities.stories;
 
  if (Object.keys(stories).length > 0) {
    Object.keys(stories).map(function(key) {

      delete stories[key];

    })  
  } 
  return dispatch(fetchStoryLocations())
}


export const GET_RESEARCHERS_LOCATIONS_REQUEST = 'GET_RESEARCHERS_LOCATIONS_REQUEST'
export const GET_RESEARCHERS_LOCATIONS_SUCCESS = 'GET_RESEARCHERS_LOCATIONS_SUCCESS'
export const GET_RESEARCHERS_LOCATIONS_FAILURE = 'GET_RESEARCHERS_LOCATIONS_FAILURE'

const fetchResearchLocations = () => ({
  [CALL_API]: {
    types: [ GET_RESEARCHERS_LOCATIONS_REQUEST, GET_RESEARCHERS_LOCATIONS_SUCCESS, GET_RESEARCHERS_LOCATIONS_FAILURE ],
    endpoint: `/wp-json/wp/v2/researchers/`,
    schema: Schemas.RESEARCHERS_ARRAY
  }
})

export const loadResearchLocations = () => (dispatch, getState) => {
  const researchers = getState().entities.researchers;
 
  if (Object.keys(researchers).length > 0) {
    Object.keys(researchers).map(function(key) {

      delete researchers[key];

    })  
  } 
  return dispatch(fetchResearchLocations())
}
 