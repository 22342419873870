import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ text }) => {
  return (
    <div className="fullscreenloader">
      <div className="loaderContainer">
        <img src="/images/loadingAnimation.gif" />
        <p>Loading...</p>  
      </div> 
    </div>
    
  );
};

// Props
Loader.propTypes = {
  text: PropTypes.string,
};
Loader.defaultProps = {
  text: '',
};

export default Loader;
